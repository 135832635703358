import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { stringify } from 'querystring';
import {
  staticResourceApiUrl, signInfo,
  publicKey
} from './config';


const { REQUEST_IMG_URL, REQUEST_IMG_URL_2, DOWNLOAD_VIDEO_URL, DOWNLOAD_VIDEO_URL_2 } = staticResourceApiUrl;

const { appId, appKey } = signInfo;

const verify = new JSEncrypt();
//设置公钥
verify.setPublicKey('-----BEGIN PUBLIC KEY-----' + publicKey + '-----END PUBLIC KEY-----')

const BigNumber = require('big-number');

//进制转换
function covertHexToDec (bigInt) {
  const num = bigInt.split('');
  let sum = 0;
  while (num.length) {
    const currentNum = num.shift();
    const currentLen = num.length;
    sum = BigNumber(sum).plus(
      BigNumber(14)
        .pow(currentLen)
        .multiply(parseInt(currentNum, 14)),
    );
  }
  return sum;
}

export const getFullCodeValue = string => {
  const reg = new RegExp('E', 'g');
  return covertHexToDec(string.replace(reg, '0')).toString();
};

export const getCodeValue = string => {
  const reg = new RegExp('E', 'g');
  const str = covertHexToDec(string.replace(reg, '0')).toString();
  return str.slice(1, str.length);
};

export const queryParamToString = params => {
  const queries = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && params[key]) {
      queries.push(`${key}=${params[key]}`);
    }
  }
  queries.sort();
  return queries.join('&');
};

export const getImgurl = (path, filename, tenantCode) => {
  const sign = CryptoJS.MD5(appId + queryParamToString({ filename, path, tenantCode }) + appKey).toString();
  const query = { path, filename, appId, sign, tenantCode };
  return REQUEST_IMG_URL + '?' + stringify(query);
};

export const getImgurlByFile = (file, tenantCode) => {
  const sign = CryptoJS.MD5(appId + queryParamToString({ file, tenantCode }) + appKey).toString();
  const query = { file, appId, sign, tenantCode };
  return REQUEST_IMG_URL_2 + '?' + stringify(query);
};

export const getVideourl = (path, filename, tenantCode) => {
  const sign = CryptoJS.MD5(appId + queryParamToString({ filename, path, tenantCode }) + appKey).toString();
  const query = { path, filename, appId, sign, tenantCode };
  return DOWNLOAD_VIDEO_URL + '?' + stringify(query);
};

export const getVideourlByFile = (file, tenantCode) => {
  const sign = CryptoJS.MD5(appId + queryParamToString({ file, tenantCode }) + appKey).toString();
  const query = { file, appId, sign, tenantCode };
  return DOWNLOAD_VIDEO_URL_2 + '?' + stringify(query);
};

export function getQueries (url) {
  let search;
  if (url) {
    search = url.split('?')[1]
  } else {
    search = location.href.split('?')[1] || '';
  }
  return search.split('&').reduce((res, q) => {
    const [key, value] = q.split('=');
    if (key && value) {
      res[key] = value;
    }
    return res;
  }, {});
}

//设置文档title   向ios app发送更改title事件(兼容ios)
export const setTitleCompatIos = (title) => {
  document.title = title;
  try {
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      window.webkit?.messageHandlers?.changeTitle.postMessage(title);
    }
  } catch (err) {
    console.log(err);
  }
};


export const verifySign = (data, signature) => {
  try {
    return verify.verify(data, signature, CryptoJS.SHA1)
  } catch (error) {
    throw new Error('密钥解析错误')
  }
}
