//签名信息(微点)
export const signInfo = {
  appId: 'web_trace',
  appKey: 'MR3axyoWemPpgLlh'
};

//签名信息(邮政)
// export const signInfo = {
//   appId: 'ems',
//   appKey: 'CStbU7x5bRjIyBif'
// }

//静态资源请求地址
export const staticResourceApiUrl = {
  REQUEST_IMG_URL: '/v1/tenant/files/open/image/view',
  REQUEST_IMG_URL_2: '/v1/tenant/files/open/image/view/fullFilename',
  DOWNLOAD_VIDEO_URL: '/v1/tenant/files/open/video/view',
  DOWNLOAD_VIDEO_URL_2: '/v1/tenant/files/open/video/view/fullFilename',
};

//后端接口地址
export const apiUrl = {
  getDataByOriginCode: '/v1/codeValues/open/findByTenantCodeAndFullNoValue',//根据租户编码和原始码值查询码值信息
  getDataByEncryptNoValue: '/v1/traceSourceTemplates/open/findInfoByEncryptNoValue', //根据加密码值查询码值信息
  getUrlWithSign: '/v1/codeValues/open/findByTenantCodeAndNoValue',
  getDataByNoValue: '/v1/traceSourceTemplates/open/findInfoByNoValue', //根据业务码值查询码值信息
  getScanRecord: '/v1/codeValueRecords/open/findByTenantCodeAndTypeAndNoValue',//获取扫码信息
  getOSSMedia: '/v1/tenant/files/open/url', //获取OSS媒体文件地址
  getOSSMediaByFile: '/v1/tenant/files/open/fullFilename/url' //获取OSS媒体文件地址
};


//高德经纬度获取行政区  详情https://lbs.amap.com/api/webservice/guide/api/georegeo
export const externalApiInfo = {
  url: '/v3/geocode/regeo',//接口地址
  key: 'f063cfa1c2c73474215503fbb3007383'//密钥
};

//验证url是否伪造的sign的公钥
export const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbGmAWClP2I0asSEXqutOrxLP2OUzYmLfuhAIU2CSkqgUaLvAY5Z8qxJXBWU+JaZcCbFhB/0Fj7cTJK+RJFdCv3cFF/yi+S47VXqCs2zi2LmdWnd0yjj9IUE2+HfFXpzAmP55BsaaAG8QjhPvVoVpqmqlxvsVfwIf1r2hyntrh8wIDAQAB';
