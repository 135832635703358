<template>
  <van-button :style="style" :size="size" @click="goBack" type="primary" :class="{ paddingbutton: size !== 'mini' }">重新扫码</van-button>
</template>

<script>
import { defineComponent } from 'vue';
import { Button } from 'vant';
import wx from 'weixin-js-sdk';

export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'small',
      required: false,
    },
    style: {
      type: String,
      required: false,
    },
  },
  setup() {
    const goBack = () => {
      wx.miniProgram.navigateBack({ delta: 1 });
    };
    return {
      goBack,
    };
  },
  components: {
    [Button.name]: Button,
  },
});
</script>

<style scoped lang="less">
@import '/src/index.less';
.paddingbutton {
  padding: 10 / @rem 120 / @rem;
}
</style>
