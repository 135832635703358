import { createApp } from 'vue';
import { Lazyload, Image as VanImage, Notify, Row, Col, Collapse, CollapseItem, Swipe, SwipeItem } from 'vant';
import App from './App.vue';
import BackButton from './components/buttons/restart-scan.vue';

if (location.href.includes("mode=$debug$")) {
  import(
    /* webpackChunkName: "vconsole" */
    "vconsole"
  ).then(({ default: VConsole }) => {
    new VConsole();
  });
}

(function () {
  var styleN = document.createElement('style');
  var width = document.documentElement.clientWidth / 16;
  styleN.innerHTML = 'html{font-size:' + width + 'px!important}';
  document.head.appendChild(styleN);
})();

const app = createApp(App);



app
  .use(Lazyload, {
    lazyComponent: true,
  })
  .use(VanImage)
  .use(Row)
  .use(Col)
  .use(Collapse)
  .use(CollapseItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(Notify)
  .component('BackButton', BackButton);

app.mount('#app');
