<template>
  <div v-if="query.from === 'uni-tag'" class="uni-tag-result-wrapper">
    <div :class="['card', result]">
      <div class="code" :hidden="noValue === ''">产品编号：{{ noValue }}</div>
    </div>
    <div v-if="result === 'fake'" class="fake-content">
      <p>该鉴定结果仅供参考，以实际官方回复为准。</p>
      <p>您可点击下方按钮，将产品相关信息反馈至厂商。</p>
    </div>
    <div v-if="result === 'real'" class="real-content">
      <div class="history">
        <div class="row">
          <div class="left">扫码次数：</div>
          <div class="right">{{ history.length }}次</div>
        </div>
        <div class="row">
          <div class="left">扫码时间：</div>
          <div class="right">
            <div class="timeline">
              <div v-for="(item, index) in history" :key="index" class="item">
                <div class="time">{{ item.operateTime }}</div>
                <div class="location">{{ item.address || "暂无数据" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="trace">
        <div class="header">溯源信息</div>
        <trace-source />
      </div>
    </div>
    <div v-if="result === 'fake'" class="feedback" @click="onFeedback">立即反馈</div>
  </div>
  <trace-source v-else />
  <div class="footer">
    <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"
      >京ICP备2021015579号-2</a
    >
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import traceSource from "./view/tracesource.vue";
import wx from "weixin-js-sdk";
import request from "./http";
import CryptoJS from "crypto-js";
import { signInfo, apiUrl } from "./config";
import { queryParamToString, getQueries, setTitleCompatIos } from "./utils";

//加密
const getSign = (params) => {
  return CryptoJS.MD5(
    signInfo.appId + queryParamToString(params) + signInfo.appKey
  ).toString();
};

// function formatDate(timestamp) {
//   const date = new Date(timestamp);
//   return `${date.getFullYear()}.${(date.getMonth() + 1)
//     .toString()
//     .padStart(2, "0")}.${date
//     .getDate()
//     .toString()
//     .padStart(2, "0")} ${date
//     .getHours()
//     .toString()
//     .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
// }

export default defineComponent({
  components: { traceSource },
  data() {
    return {
      canNavigate: true,
    };
  },
  setup() {
    const query = getQueries();
    const noValue = ref("");
    const tenantCode = ref("");
    const result = ref("");
    noValue.value = query.noValue ?? "";
    tenantCode.value = query.tenantCode ?? "";
    result.value = query.result ?? "";

    const history = ref([]);

    onMounted(() => {
      if (query.from === "uni-tag") {
        setTitleCompatIos("鉴伪详情");
        if (result.value === "real") {
          getHistory();
        }
      }
    });

    return {
      noValue,
      tenantCode,
      result,
      history,
      query,
    };

    async function getHistory() {
      try {
        const params = {
          noValue: noValue.value,
          source: 1, //数据源 通用标签
          tenantCode: tenantCode.value,
          type: 3, //扫码类型
        };
        const sign = getSign(params);

        const { data, code, errorMessage } = await request.request({
          url: apiUrl.getScanRecord,
          params: {
            ...params,
            appId: signInfo.appId,
            sign,
          },
          method: "GET",
        });
        if (code === 0) {
          history.value = data;
        } else {
          console.log(errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    onFeedback() {
      // 防抖
      if (this.canNavigate) {
        this.canNavigate = false;
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.navigateTo({
              url: "../feedback/index",
              complete: () => {
                // 真机上complete回调执行后，仍然存在页面可操作的情况，加一段延时抵消
                setTimeout(() => {
                  this.canNavigate = true;
                }, 300);
              },
            });
          }
        });
      }
    },
  },
});
</script>

<style lang="less" scoped>
.uni-tag-result-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding-top: 15px;
  overflow: hidden;
  background: #f5f7fb url(./assets/identify-bg@2x.png) center no-repeat;
  background-size: cover;
  .card {
    flex: none;
    height: 175px;
    position: relative;
    margin: 0 12px 15px;
    border-radius: 8px;
    overflow: hidden;
    &.real {
      background: transparent url(./assets/identify-real@2x.png) center no-repeat;
      background-size: auto 100%;
    }
    &.fake {
      background: transparent url(./assets/identify-fake@2x.png) center no-repeat;
      background-size: auto 100%;
    }

    .code {
      position: absolute;
      bottom: 16px;
      left: 8px;
      right: 8px;
      word-break: break-all;
      line-height: 14px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .fake-content {
    flex: auto;
    padding: 0 12px;
    text-align: center;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    white-space: pre-wrap;
    p {
      margin: 0;
    }
  }

  .real-content {
    flex: auto;
    padding: 0 12px;
    overflow-y: auto;

    .history {
      box-sizing: border-box;
      padding: 20px 12px 0;
      margin-bottom: 12px;
      background: #ffffff;
      border-radius: 8px;
      overflow-y: auto;

      .row {
        display: flex;
        margin-bottom: 10px;
        line-height: 14px;

        .left {
          flex: none;
          width: 70px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .right {
          flex: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          .timeline {
            max-height: 175px;
            overflow-y: auto;
          }
        }
      }
    }

    .trace {
      margin-bottom: 15px;
      padding: 20px 12px;
      background: #ffffff;
      border-radius: 8px;

      .header {
        line-height: 15px;
        padding-bottom: 12px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        border-bottom: 1px solid #efefef;
      }
    }
  }

  .feedback {
    flex: none;
    margin: 0 12px;
    height: 45px;
    line-height: 45px;
    margin-bottom: 50px;
    text-align: center;
    border-radius: 5px;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    background: #1677ff;
  }

  .timeline {
    position: relative;

    .item {
      position: relative;
      padding-left: 30px;
      padding-bottom: 5px;
      line-height: 28px;
      margin-bottom: 1px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #999;
      }

      &::after {
        content: "";
        position: absolute;
        left: 3px;
        top: 0;
        width: 0;
        height: 100%;
        border-left: 1px dotted #999;
      }

      &:first-child::after {
        top: 14px;
        height: calc(100% - 14px);
      }

      &:last-child::after {
        height: 14px;
      }

      .time {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .location {
        line-height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.footer {
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  a {
    color: #999;
  }
}
</style>
