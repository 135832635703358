<template>
  <div v-if="serverInternalError" class="serverInternalError">正在更新版本中,请稍后</div>
  <van-empty v-else :class="{ empty: !fake.show, fake: fake.show }" :image="unAuthorized ? unauthorized : !fake.show ? notFind : fake.isFake ? fakeTag : realTag">
    <template #description>
      <template v-if="unAuthorized">
        <p class="center tip">很抱歉，未查询到该码值</p>
        <!-- <p class="center tip">(状态码:4005)</p> -->
      </template>
      <template v-else>
        <p class="center tip weight" v-if="fake.show">该扫码结果为“{{ fake.isFake ? '非' : null }}官方标签”</p>
        <p class="center tip">
          当前搜索码值
          <br />
          “{{ noValue }}”
          <template v-if="relevanceCodeValue">
            <br />
            外部关联码值
            <br />
            “{{ relevanceCodeValue }}”
          </template>
        </p>
        <p class="center tip">目前该码值未绑定商品信息</p>
      </template>
    </template>
    <BackButton v-if="showButton" />
  </van-empty>
</template>

<script>
import { defineComponent } from 'vue';
import { Empty } from 'vant';
import notFind from '../assets/not-find.png';
import unauthorized from '../assets/unauthorized.png';
import fakeTag from '../assets/fake-tag.png';
import realTag from '../assets/real-tag.png';
import BackButton from '../components/buttons/restart-scan.vue';

export default defineComponent({
  props: {
    showButton: {
      type: Boolean,
      required: true,
    },
    unAuthorized: {
      type: Boolean,
      required: true,
    },
    noValue: {
      type: [String, Number, null],
      required: true,
    },
    relevanceCodeValue: {
      type: [String, Number, null],
      required: false,
    },
    fake: {
      type: Object,
      required: true,
    },
    serverInternalError: {
      type: Boolean,
      required: true,
    },
  },
  setup () {
    return {
      notFind,
      unauthorized,
      fakeTag,
      realTag,
    };
  },
  components: {
    [Empty.name]: Empty,
    BackButton,
  },
});
</script>

<style scoped lang="less">
@import '../index.less';
.center {
  text-align: center;
}
.tip {
  font-size: 32 / @rem;
}
.weight {
  font-weight: bold;
}
.bottom-button {
  padding: 0 120 / @rem;
}
.empty {
  margin-top: 271 / @rem;
}
.empty/deep/ .van-empty__image {
  width: 519 / @rem;
  height: 306 / @rem;
}
.fake {
  margin-top: 187 / @rem;
}
.fake/deep/ .van-empty__image {
  width: 484 / @rem;
  height: 390 / @rem;
}
.serverInternalError {
  width: 100vw;
  height: 100vh;
  text-align: center;
  font-size: 32 / @rem;
  line-height: 100vh;
}
</style>
