<template>
  <div class="loading" v-if="loading">
    <van-loading vertical>获取信息中...</van-loading>
  </div>
  <div class="result" v-else>
    <!-- 无商品 未授权 鉴伪信息(无商品 未授权状态下) 服务器内部错误信息 -->
    <no-result v-if="hasCommodity === false" :showButton="showButton" :noValue="noValue" :relevanceCodeValue="relevanceCodeValue" :unAuthorized="unAuthorized" :fake="fake" :serverInternalError="serverInternalError"></no-result>
    <div class="detail" v-else>
      <!-- 排序 -->
      <template v-for="(curr, index) in sortedList" :key="index">
        <div class="brand part" v-if="curr === ESortCode.carousel">
          <!-- 鉴伪信息(有商品 授权状态下) -->
          <van-image class="isfake" v-if="fake.show" :src="fake.isFake ? fakeImgUrl : realImgUrl">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <!-- 是否有溯源模板  是 展示溯源模板信息-->
          <template v-if="hasTemplate">
            <!-- 是否是默认展示模板  是展示默认头图-->
            <van-image class="banner" v-if="isDefaultDisplayTemplate" :src="wecodeImgUrl" alt="加载失败">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <!-- 不是 展示模板中的轮播图 -->
            <van-swipe v-else :autoplay="2000" lazy-render>
              <van-swipe-item v-for="item in backgroundList" :key="item.backgroundPath">
                <van-image class="banner" :src="getImgurl(item.backgroundPath, item.backgroundFilename, tenantCode)" alt="加载失败">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </van-swipe-item>
            </van-swipe>
          </template>
          <!-- 是否有溯源模板 否展示商品信息-->
          <van-image class="banner" v-else :src="getImgurl(dataSource.product.imagePath, dataSource.product.imageFilename, tenantCode)" alt="加载失败">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
        <!-- 品牌logo与title   有展示模板则获取展示模板中的logo与品牌  没有则是商品信息中的logo与品牌-->
        <div class="brand brandlogo part" v-if="showLogoAndTitle && curr === ESortCode.brand">
          <div class="brand-title">
            <van-row>
              <van-col span="4" offset="1" style="text-align: center">
                <van-image class="logo" v-if="hasTemplate" round :src="getImgurl(dataSource.template.viewTemplate.logoPath, dataSource.template.viewTemplate.logoFilename, tenantCode)" alt="加载失败">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <van-image class="logo" v-else round :src="getImgurl(dataSource.product.brand.logoPath, dataSource.product.brand.logoFilename, tenantCode)" alt="加载失败">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </van-col>
              <van-col span="17" offset="2">
                <span class="brandName" v-if="dataSource">
                  {{ hasTemplate ? dataSource.template.viewTemplate.brandName : dataSource.product.brand.name ? dataSource.product.brand.name : '北京微点科技有限公司' }}
                </span>
              </van-col>
            </van-row>
          </div>
        </div>
        <template v-if="curr === ESortCode.traceSource">
          <!-- 溯源模板解析列表(溯源环节) -->
          <van-collapse class="info part" v-for="item in traceSourceData" :key="item.id" v-model="item.activeKey">
            <van-collapse-item :title="item.nodeName" :name="item.id" :value="item.activeKey && item.activeKey.length > 0 ? '收起' : '展开'">
              <div class="field" v-for="i in item.fields" :label="i.name" :key="i.id">
                <template v-if="i.isShow">
                  <!-- label展示 不为url(链接) 不为采集类型链接 则展示为普通文本 -->
                  <span class="label" v-if="i.name && i.type !== EUploadSecondaryTypeEnum.url && i.type !== ECollcetSecondaryTypeEnum.url">{{ i.name }}&nbsp;&nbsp;&nbsp;</span>
                  <!-- 数据源 内部系统 -->
                  <template v-if="i.dataSource === EDataSourceTypeEnum.systemInfo">
                    <!-- 包含在文本类型字段列表中的字段展示为文本 -->
                    <span class="value wrap" v-if="systemInfoSecondaryTypeIsTextList.includes(i.type)">{{ systemInfo[i.type] ? systemInfo[i.type] : '未关联外部码值' }}</span>
                    <!-- 未包含在文本类型字段列表中的字段展示为图片 -->
                    <van-image v-else :src="systemInfo[i.type]" alt="加载失败" class="img2">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                  </template>
                  <!-- 数据源 本地上传 -->
                  <template v-if="i.dataSource === EDataSourceTypeEnum.upload">
                    <!-- 文本类型展示为文本 -->
                    <span class="value wrap" v-if="i.type === EUploadSecondaryTypeEnum.text">{{ i.content }}</span>
                    <!-- 链接类型展示为链接 -->
                    <a :href="i.content" v-if="i.type === EUploadSecondaryTypeEnum.url">{{ i.name }}</a>
                    <!-- 图片链接类型展示为图片链接 -->
                    <a :href="i.content.url" v-if="i.type === EUploadSecondaryTypeEnum.imageurl">
                      <van-image v-if="i.content.path" :src="getImgurl(i.content.path, i.content.filename, tenantCode)" alt="加载失败" class="img2">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                      <van-image v-else :src="defaultImage" alt="加载失败" class="img2">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </a>
                    <!-- 电话类型展示为链接 -->
                    <a :href="'tel:' + i.content" v-if="i.type === EUploadSecondaryTypeEnum.phone">{{ i.content }}</a>
                    <!-- 图片类型展示为图片列表 -->
                    <template v-if="i.type === EUploadSecondaryTypeEnum.image">
                      <van-image v-for="(imgitem, index) in json(i.content)" :key="index" :src="getImgurl(imgitem.path, imgitem.filename, tenantCode)" alt="加载失败" class="img2">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </template>
                    <!-- 视频类型展示为视频列表 -->
                    <template v-if="i.type === EUploadSecondaryTypeEnum.video">
                      <video v-for="(videoitem, index) in i.content" autoplay :key="index" :src="videoitem.src" playsinline controls class="img2" alt="加载失败"></video>
                    </template>
                  </template>
                  <!-- 数据源 溯源app采集信息 -->
                  <template v-if="i.dataSource === EDataSourceTypeEnum.appCollecting">
                    <!-- 如果数据采集数据有相对应的字段,则展示 -->
                    <template v-if="collectInfo[i.key]">
                      <!--链接类型 -->
                      <a :href="collectInfo[i.key]" v-if="i.type === ECollcetSecondaryTypeEnum.url">{{ i.name }}</a>
                      <!--电话类型 -->
                      <a :href="'tel:' + collectInfo[i.key]" v-if="i.type === ECollcetSecondaryTypeEnum.phone">{{ collectInfo[i.key] }}</a>
                      <!--图片类型 -->
                      <template v-if="i.type === ECollcetSecondaryTypeEnum.image">
                        <van-image v-for="imgitem in collectInfo[i.key].split(',')" :key="imgitem" :src="getImgurlByFile(imgitem, tenantCode)" alt="加载失败" class="img2">
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                      </template>
                      <!--视频类型 -->
                      <template v-if="i.type === ECollcetSecondaryTypeEnum.video">
                        <video v-for="(videoitem, index) in collectInfo[i.key]" :poster="getImgurlByFile(videoitem.image, tenantCode)" :key="index" :src="videoitem.src" playsinline autoplay controls class="img2" alt="加载失败"></video>
                      </template>
                      <!--其他类型 展示为文本 -->
                      <span class="wrap" v-if="ECollcetSecondaryTypeEnum.others.includes(i.type)">
                        {{ collectInfo[i.key] }}
                      </span>
                    </template>
                    <!-- 如果数据采集数据没有相对应的字段,则展示 -->
                    <template v-else>
                      <span>暂未上传采集信息</span>
                    </template>
                  </template>
                  <!-- 数据源 扫码信息 -->
                  <template v-if="i.dataSource === EDataSourceTypeEnum.info">
                    <div class="history">
                      <div class="row" v-if="i.type === EScanRecordTypeEnum.times">
                        <div class="left">扫码次数：</div>
                        <div class="right">{{ history.length }}次</div>
                      </div>
                      <div class="row" v-if="i.type === EScanRecordTypeEnum.record">
                        <div class="left">扫码时间：</div>
                        <div class="right">
                          <div class="timeline" v-if="history.length > 0">
                            <div v-for="(item, index) in history" :key="index" class="item">
                              <div class="time">{{ item.operateTime }}</div>
                              <div class="location">{{ item.address || '暂无数据' }}</div>
                            </div>
                          </div>
                          <span v-else>暂无数据</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </van-collapse-item>
          </van-collapse>
        </template>
        <!-- 没有溯源模板 则展示商品信息 -->
        <van-collapse class="info part" v-if="!hasTemplate && curr === ESortCode.traceSource" v-model="activeKey">
          <van-collapse-item title="商品信息" name="1" :value="activeKey.length > 0 ? '收起' : '展开'">
            <div class="field">
              <span class="label">商品名称:</span>
              <span class="value">{{ dataSource.product?.name }}</span>
            </div>
            <div class="field">
              <span class="label">商品编码:</span>
              <span class="value">{{ dataSource.product?.code }}</span>
            </div>
            <div class="field">
              <span class="label">批次号:</span>
              <span class="value">{{ dataSource.codeBatch?.code }}</span>
            </div>
            <div class="field">
              <span class="label">生产日期:</span>
              <span class="value">{{ dataSource.codeBatch?.producedDate }}</span>
            </div>
          </van-collapse-item>
        </van-collapse>
        <!-- 多张展示图 -->
        <template v-if="extraList && extraList.length > 0 && curr === ESortCode.extraList">
          <div class="brand extraitem part" v-for="(item, index) in extraList" :key="index">
            <van-image class="banner" :src="getImgurl(item.extraPath, item.extraFilename, tenantCode)" alt="加载失败">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref, computed } from 'vue';
import { Loading, Notify } from 'vant';
import request from '../http';
import { getImgurl, getImgurlByFile, getQueries, queryParamToString, setTitleCompatIos, verifySign } from '../utils';
import wecodeImgUrl from '../assets/wecode.png';
import wecodeLogoImgUrl from '../assets/logo.png';
import fakeImgUrl from '../assets/fake.png';
import realImgUrl from '../assets/real.png';
import defaultImage from '../assets/default-image.png';
import { signInfo, /*externalApiInfo,*/ apiUrl } from '../config';
import CryptoJS from 'crypto-js';
import NoResult from './no-result.vue';
import wx from 'weixin-js-sdk';

//溯源环节 数据源 dataSource
//1 内部系统 (企业信息)
//2 本地上传
//3 app采集信息  子类型  2:图片  3:视频  10:链接  11:电话 其他为文本
//4 扫码信息

//溯源数据源 类型枚举集合
const EDataSourceTypeEnum = {
  systemInfo: 1, //内部系统
  upload: 2, //本地上传
  appCollecting: 3, //app采集信息
  info: 4, //扫码信息
};

//内部系统 子类型枚举集合
const ESystemInfoSecondaryTypeEnum = {
  //数据源为内部系统时 下面字段为文本 其他字段为图片 企业名称 品牌名称 商品编码 商品名称 批次号 商品生产日期 业务码值 关联码值
  //其他的为图片
  tenantName: 'tenantName',
  brandName: 'brandName',
  productCode: 'productCode',
  productName: 'productName',
  batchCode: 'batchCode',
  producedDate: 'producedDate',
  noValue: 'noValue',
  relevanceCodeValue: 'relevanceCodeValue',
};

//内部系统 类型为文本的信息字段(key)列表
const systemInfoSecondaryTypeIsTextList = [ESystemInfoSecondaryTypeEnum.tenantName, ESystemInfoSecondaryTypeEnum.brandName, ESystemInfoSecondaryTypeEnum.productCode, ESystemInfoSecondaryTypeEnum.productName, ESystemInfoSecondaryTypeEnum.batchCode, ESystemInfoSecondaryTypeEnum.producedDate, ESystemInfoSecondaryTypeEnum.noValue, ESystemInfoSecondaryTypeEnum.relevanceCodeValue];

//本地上传  子类型枚举集合
const EUploadSecondaryTypeEnum = {
  text: 'text', //文本
  image: 'image', //图片
  video: 'video', //视频
  url: 'url', //链接
  phone: 'phone', //电话
  imageurl: 'imageurl',
};

//app采集信息  子类型枚举集合
const ECollcetSecondaryTypeEnum = {
  image: 2, //图片
  video: 3, //视频
  url: 10, //链接
  phone: 11, //电话
  others: [1, 4, 5, 6], //其他类型 其他类型展示文本
};

//扫码信息  子类型枚举集合
const EScanRecordTypeEnum = {
  times: 1, //次数
  record: 2, //记录
};

const { appId, appKey } = signInfo;

//展示情况  未授权  未绑定商品  已绑定商品(商品信息  溯源信息)
//已绑定商品展示信息 有溯源信息展示溯源信息 没有展示商品信息
//溯源信息分为展示模板(头部轮播图 尾部图片列表)  溯源模板(中间溯源环节)

//溯源结构不同部分的排序列表code
const ESortCode = {
  carousel: 'backgroundList', //轮播图列表
  brand: 'brand', //品牌信息或者商品信息
  traceSource: 'traceSource', //溯源信息
  extraList: 'extraList', //其他图片列表
};

//溯源结构不同部分的默认排序列表
const defaultSortedList = [ESortCode.carousel, ESortCode.brand, ESortCode.traceSource, ESortCode.extraList];

export default defineComponent({
  setup() {
    const state = reactive({
      loading: true, //加载状态
      serverInternalError: false, //是否服务器内部错误
      dataSource: null, //数据
      tenantCode: null, //企业ID
      templateList: [], //溯源模板解析列表
      hasTemplate: true, //是否有溯源模板
      noValue: null, //业务码值
      relevanceCodeValue: null, //关联码值
      extraList: [], //下方额外图片列表
      backgroundList: [], //头部轮播图列表
      sortedList: [], //不同展示模块的展示顺序列表
      history: [], //扫码信息
      fake: {
        //鉴伪结果
        show: true, //是否展示
        isFake: true, //是真是伪
      },
      isDefaultDisplayTemplate: false, //是否为默认展示模板
    });
    const query = getQueries();
    //展示返回按钮
    const showButton = ref(false);
    //是否仅是商品信息
    const hasCommodity = ref(false);
    //是否是未授权
    const unAuthorized = ref(false);

    onMounted(() => {
      //小程序环境将返回按钮显示
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          showButton.value = true;
        }
      });
      //根据把不同url参数分发进行不同的业务逻辑
      distributeByUrlQuery();
    });

    //根据不同的url参数分发不同的业务
    const distributeByUrlQuery = () => {
      //根据加密码值查询信息 适应微点码一对一链接生成的二维码 扫码出来的加密url参数a与b a:加密码值
      if (query.a) {
        const a = decodeURIComponent(query.a);
        const params = { noValue: a };
        const sign = getSign(params);
        //第一个参数设置为 加密
        getTraceDataBycode(true, sign, undefined, a);
      }
      //如果参数中有业务码值
      else if (query.noValue) {
        const { tenantCode, noValue, fullNoValue, sign } = query;
        verify(sign, { tenantCode, noValue, fullNoValue });
      }
      //如果参数中有原始码值
      else if (query.fullNoValue) {
        if (!query.tenantCode) {
          query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
          hasCommodity.value = false;
          state.loading = false;
          unAuthorized.value = true;
          return;
        }
        const sign = getSign({
          fullNoValue: query.fullNoValue,
          tenantCode: query.tenantCode,
        });
        //调取原始码值查询接口
        getDataByOrigincode(query.fullNoValue, sign, query.tenantCode);
      }
      //没有上述参数则未授权
      else {
        query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
        hasCommodity.value = false;
        state.loading = false;
        unAuthorized.value = true;
        return;
      }
      //防伪信息结果展示 如果参数中有isFake 说明是防伪信息展示
      if (query.isFake) {
        state.fake.show = true;
        state.fake.isFake = query.isFake === 'true';
        query.from !== 'uni-tag' && setTitleCompatIos('鉴伪信息');
      } else {
        state.fake.show = false;
      }
    };

    //加密
    const getSign = params => {
      return CryptoJS.MD5(appId + queryParamToString(params) + appKey).toString();
    };

    const verify = async (antiFakeSign, { tenantCode, noValue, fullNoValue }) => {
      //校验是否为伪造请求
      const pass = verifySign(queryParamToString({ tenantCode, noValue, fullNoValue }), decodeURIComponent(antiFakeSign));
      if (!pass) {
        state.loading = false;
        unAuthorized.value = true;
        return;
      }
      const sign = getSign({
        noValue: noValue,
        tenantCode: tenantCode,
      });
      //第一个参数设置为 不加密
      getTraceDataBycode(false, sign, tenantCode, noValue);
    };

    //企业内部信息Map(对标数据源为系统信息  1)
    const systemInfo = ref(null);

    //根据原始码值获取数据
    const getDataByOrigincode = async (fullNoValue /**原始码值*/, sign /**签名*/, tenantCode /**企业ID*/) => {
      try {
        state.loading = true;
        const { code, data } = await request.request({
          url: apiUrl.getDataByOriginCode,
          params: { fullNoValue, appId, sign, tenantCode },
          method: 'get',
        });
        if (code === 0) {
          if (!data) {
            query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
            hasCommodity.value = false;
            state.loading = false;
            unAuthorized.value = true;
            return;
          }
          const { tenantCode, noValue } = data;
          state.tenantCode = tenantCode;
          state.noValue = noValue;
          if (!tenantCode) {
            query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
            hasCommodity.value = false;
            state.loading = false;
            unAuthorized.value = true;
            return;
          }
          hasCommodity.value = true;
          const newsign = getSign({ noValue, tenantCode });
          getTraceDataBycode(false, newsign, tenantCode, noValue);
        }
      } catch (error) {
        if (/502/.test(error.message)) {
          state.serverInternalError = true;
          state.loading = false;
        }
        Notify({ type: 'danger', message: error.message });
      } finally {
        state.loading = false;
      }
    };

    //根据业务码值获取数据
    const getTraceDataBycode = async (encryption /**是否为加密参数*/, sign /**签名*/, tenantCode /**企业ID*/, noValue /**业务码值*/) => {
      try {
        const { code, data } = await request.request({
          url: encryption ? apiUrl.getDataByEncryptNoValue : apiUrl.getDataByNoValue,
          params: encryption ? { a: noValue, appId, sign } : { noValue, appId, sign, tenantCode },
          method: 'get',
        });
        if (code === 0 && data) {
          //外链跳转  linkType 1:内链 2:外部链接  外部链接直接跳转
          if (data.codeValue && data.codeValue.linkType === 2) {
            location.replace(data.codeValue.baseUrl + (data.codeValue.urlParam ? '?' + data.codeValue.urlParam : '')); //链接参数
            return;
          }
          state.dataSource = data;
          state.noValue = data.codeValue.noValue;
          if (encryption) {
            state.tenantCode = data.tenant?.tenantCode;
          } else {
            state.tenantCode = tenantCode;
          }
          if (noValue) {
            unAuthorized.value = !data.authorization;
            if (unAuthorized.value) {
              //是未授权的码
              query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
              hasCommodity.value = false;
              state.loading = false;
              return;
            }
          }
          if (!data.product) {
            //没有商品信息
            if (state.fake.show === false) {
              query.from !== 'uni-tag' && setTitleCompatIos('标签信息');
            }
            //如果有外部关联码值,则展示外部关联码值
            if (data.codeValue.relevanceCodeValue) {
              state.relevanceCodeValue = data.codeValue.relevanceCodeValue;
            }
            //排列顺序列表
            state.sortedList = defaultSortedList;
            hasCommodity.value = false;
            state.loading = false;
            unAuthorized.value = false;
            return;
          } else {
            //有商品信息 获取企业信息(数据源为 系统内部信息时 展示的信息)
            systemInfo.value = {
              tenantName: data.tenant.companyName,
              tenantUrl: getImgurl(data.tenant.logoPath, data.tenant.logoFilename, tenantCode),
              brandName: data.product.brand.name,
              brandUrl: getImgurl(data.product.brand.logoPath, data.product.brand.logoFilename, tenantCode),
              productCode: data.product.code,
              productName: data.product.name,
              productUrl: getImgurl(data.product.imagePath, data.product.imageFilename, tenantCode),
              batchCode: data.codeBatch?.code || '',
              producedDate: data.codeBatch?.producedDate || '',
              noValue: data.codeValue.noValue,
              relevanceCodeValue: data.codeValue.relevanceCodeValue,
            };
          }
          if (data.template) {
            //有溯源模板信息
            if (state.fake.show === false) {
              query.from !== 'uni-tag' && setTitleCompatIos('溯源信息');
            }
            state.hasTemplate = true;
            hasCommodity.value = true;
            state.templateList = JSON.parse(data.template.fileContent);
            //获取采集数据
            if (data.templateCollect && data.templateCollect.collectionData) {
              collectInfo.value = data.templateCollect.collectionData;
            }
            //是否为默认展示模板
            if (data.template.viewTemplate && data.template.viewTemplate.isDefault) {
              state.isDefaultDisplayTemplate = data.template.viewTemplate.isDefault;
            } else {
              state.isDefaultDisplayTemplate = false;
            }
            //将展示模板的头部轮播图与尾部列表图 解析出
            if (data.template.viewTemplate && data.template.viewTemplate.fileContent !== '') {
              const { sortedList, extraList, backgroundList } = JSON.parse(data.template.viewTemplate.fileContent);
              state.extraList = extraList;
              state.backgroundList = backgroundList;
              //排列顺序列表
              state.sortedList = sortedList && sortedList.length > 0 ? sortedList : defaultSortedList;
            } else {
              state.sortedList = defaultSortedList;
            }
            state.loading = false;
          } else {
            //没有溯源模板信息
            if (state.fake.show === false) {
              query.from !== 'uni-tag' && setTitleCompatIos('商品信息');
            }
            state.hasTemplate = false;
            //排列顺序列表
            state.sortedList = defaultSortedList;
            //商品信息
            hasCommodity.value = true;
            state.loading = false;
          }
        } else {
          hasCommodity.value = false;
          state.loading = false;
        }
      } catch (error) {
        if (/502/.test(error.message)) {
          state.serverInternalError = true;
          state.loading = false;
        }
        Notify({ type: 'danger', message: error.message });
      }
    };

    //获取扫码信息
    async function getHistory(noValue /*业务码值*/, tenantCode /*企业ID*/) {
      try {
        const params = {
          type: 3,
          noValue,
          tenantCode,
        };
        const sign = getSign(params);
        const { data, code, errorMessage } = await request.request({
          url: apiUrl.getScanRecord,
          params: {
            ...params,
            appId,
            sign,
          },
          method: 'GET',
        });
        if (code === 0) {
          state.history = data;
        } else {
          console.log(errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    }

    //采集信息Map
    const collectInfo = ref({});
    async function getVideoSrc(path, filename, tenantCode) {
      try {
        const params = {
          path,
          filename,
          tenantCode,
          timestamp: Date.now(),
        };
        const sign = getSign(params);
        const { data, code } = await request.request({
          url: apiUrl.getOSSMedia,
          params: {
            ...params,
            appId,
            sign,
          },
          method: 'GET',
        });
        if (code === 0) {
          return data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }

    async function getVideoSrcByFile(file, tenantCode) {
      try {
        const params = {
          file,
          tenantCode,
          timestamp: Date.now(),
        };
        const sign = getSign(params);
        const { data, code } = await request.request({
          url: apiUrl.getOSSMediaByFile,
          params: {
            ...params,
            appId,
            sign,
          },
          method: 'GET',
        });
        if (code === 0) {
          return data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }

    //溯源信息
    const traceSourceData = computed(
      () =>
        state.templateList.map(item => {
          if (item.nodeExpand === true) {
            //是否展开
            item.activeKey = [item.id];
          } else {
            item.activeKey = [];
          }
          item.fields.map(async i => {
            //如果是数据源是3 溯源app采集信息 从dynamicTable字段动态匹配字段
            if (i.dataSource === EDataSourceTypeEnum.appCollecting && state.dataSource.template && state.dataSource.template.dynamicTable) {
              state.dataSource.template.dynamicTable.fields.map(field => {
                if (i.phaseCode === field.phaseCode) {
                  i.key = field.name;
                }
              });
            }
            if (i.type === EUploadSecondaryTypeEnum.video) {
              // 处理视频文件走OSS接口
              const contents = json(i.content);
              for (const content of contents) {
                content.src = await getVideoSrc(content.path, content.filename, state.tenantCode);
              }
              i.content = contents;
            }
            if (i.dataSource === EDataSourceTypeEnum.appCollecting && collectInfo.value[i.key] && i.type === ECollcetSecondaryTypeEnum.video) {
              const contents = json(collectInfo.value[i.key]);
              for (const content of contents) {
                content.src = await getVideoSrcByFile(content.url, state.tenantCode);
              }
              collectInfo.value[i.key] = contents;
            }
            //如果是4 扫码信息 获取定位信息
            if (i.dataSource === 4) {
              getHistory(state.noValue, state.tenantCode);
            }
          });
          return item;
        }) || [],
    );

    const json = json => {
      return typeof json === 'string' ? JSON.parse(json) : json;
    };

    //品牌logo与title   有展示模板则获取展示模板中的logo与品牌  没有则是商品信息中的logo与品牌
    const showLogoAndTitle = computed(() => (state.hasTemplate && (state.dataSource.template.viewTemplate.logoPath || state.dataSource.template.viewTemplate.brandName)) || (!state.hasTemplate && state.dataSource.product.brand.logoPath));

    return {
      ...toRefs(state),
      traceSourceData,
      getImgurl,
      getImgurlByFile,
      activeKey: ref(['1']),
      wecodeImgUrl,
      wecodeLogoImgUrl,
      fakeImgUrl,
      realImgUrl,
      showButton,
      hasCommodity,
      unAuthorized,
      systemInfo,
      systemInfoSecondaryTypeIsTextList,
      json,
      collectInfo,
      EDataSourceTypeEnum,
      EUploadSecondaryTypeEnum,
      ECollcetSecondaryTypeEnum,
      defaultSortedList,
      ESortCode,
      showLogoAndTitle,
      defaultImage,
      EScanRecordTypeEnum,
    };
  },
  methods: {},
  components: {
    [Loading.name]: Loading,
    NoResult,
  },
});
</script>

<style scoped lang="less">
@import '../index.less';
.back {
  width: 50 / @rem;
  height: 50 / @rem;
}
.loading {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.isfake {
  position: absolute;
  z-index: 3;
  right: 10vw;
  top: 10vw;
  width: 30vw;
  height: 30vw;
  text-align: center;
  line-height: 22vw;
}
.detail {
  padding: 24 / @rem;
  background: #f6f5fb;
  // min-height: 100vh;
  .brandlogo {
    padding: 34 / @rem 0;
  }
  .extraitem {
    margin: 12 / @rem 0;
  }
  .brand {
    background: #fff;
    border-radius: 20 / @rem;
    // overflow: hidden;
    ::v-deep(.van-image) {
      border-radius: 20 / @rem;
      display: block;
    }
    .banner {
      width: 100%;
      ::v-deep(.van-image__img) {
        border-radius: 20 / @rem;
      }
    }
    .nodata {
      width: 636 / @rem;
      height: 465 / @rem;
      text-align: center;
      line-height: 465 / @rem;
    }
    .logo,
    .nodata2 {
      width: 100 / @rem;
      height: 100 / @rem;
      box-shadow: 0px 0px 10px 0px rgba(4, 0, 0, 0.1);
    }
    .nodata2 {
      text-align: center;
      font-size: 18 / @rem;
      line-height: 100 / @rem;
    }
    .brandName {
      font-weight: bold;
      font-size: 36 / @rem;
      line-height: 100 / @rem;
      color: #333333;
    }
  }
  .info {
    // margin-top: 24 / @rem;
    .field {
      margin: 31 / @rem 0;
      .value {
        padding-left: 10 / @rem;
        color: #333333;
      }
    }
    .img2 {
      width: 100%;
    }
  }
}
.part {
  margin: 12 / @rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.label {
  font-weight: bold;
}
.tipone {
  font-size: 26 / @rem;
}
.center {
  text-align: center;
}
.tiptwo {
  font-size: 22 / @rem;
}
.empty {
  margin-top: 271 / @rem;
}
.van-collapse::after {
  border-width: 0;
}
.van-collapse {
  ::v-deep(.van-cell__title) {
    font-size: 36 / @rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 59 / @rem;
  }
  ::v-deep(.van-cell__value) {
    font-size: 28 / @rem;
    color: #076aff;
  }
  ::v-deep(.van-cell__right-icon) {
    font-size: 28 / @rem;
    color: #076aff;
  }
  ::v-deep(.van-collapse-item__title) {
    border-radius: 20 / @rem;
  }
  ::v-deep(.van-collapse-item__title--expanded) {
    border-radius: 20 / @rem 20 / @rem 0 0;
  }
  ::v-deep(.van-collapse-item__wrapper) {
    border-radius: 0 0 20 / @rem 20 / @rem;
  }
}
.wrap {
  word-wrap: break-word;
  word-break: normal;
}
.history {
  box-sizing: border-box;
  padding: 20px 12px 0;
  margin-bottom: 12px;
  background: #ffffff;
  border-radius: 8px;
  overflow-y: auto;

  .row {
    display: flex;
    margin-bottom: 10px;
    line-height: 14px;

    .left {
      flex: none;
      width: 70px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    .right {
      flex: auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      .timeline {
        max-height: 175px;
        overflow-y: auto;
      }
    }
  }
}
.timeline {
  position: relative;

  .item {
    position: relative;
    padding-left: 30px;
    padding-bottom: 5px;
    line-height: 28px;
    margin-bottom: 1px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #999;
    }

    &::after {
      content: '';
      position: absolute;
      left: 3px;
      top: 0;
      width: 0;
      height: 100%;
      border-left: 1px dotted #999;
    }

    &:first-child::after {
      top: 14px;
      height: calc(100% - 14px);
    }

    &:last-child::after {
      height: 14px;
    }

    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .location {
      line-height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
