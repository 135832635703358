import axios from 'axios';
// import { localStorage } from '@/utils/local-storage';
// import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
// export const REQUEST_TOKEN_KEY = 'Access-Token';

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  timeout: 300000, // 请求超时时间
  withCredentials: true,
});

// if (process.env.NODE_ENV === 'production') {
//   request.defaults.baseURL = window.URLCONFIG.BASE_URL;
// }

//异常拦截处理器
const errorHandler = error => {
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = config => {
  return config;
};

// 响应拦截器
const responseHandler = response => {
  if (response.config?.responseType === 'blob' || response.config?.responseType === 'arraybuffer') {
    return response;
  }
  if (response.regeocode) {
    return response
  }
  return response.data;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

//mock request

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);

export default request;
